import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Test from './components/Test';
import './App.scss';
import { withResizeDetector } from 'react-resize-detector';

const AdaptiveWithDetector = withResizeDetector(Home);
const AdaptiveWithDetectorT = withResizeDetector(Test);

const App = () => {
  return (
    <div className="App">
    <Router>
    <Switch>
      <Route path="/" exact={true} component={AdaptiveWithDetector} />
      <Route path="/practice" exact={true} component={AdaptiveWithDetectorT} />
      </Switch>
      </Router>
    </div>
  );
};

export default App;
